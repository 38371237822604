import Cookies from "js-cookie"

const TOKEN_KEY = "jwt_token"
const REFRESH_TOKEN = "refresh_token"

export const setJwtToken = (token: string) => {
  Cookies.set(TOKEN_KEY, token)
}

export const getJwtToken = (): string | undefined => {
  return Cookies.get(TOKEN_KEY)
}

export const removeJwtToken = () => {
  Cookies.remove(TOKEN_KEY)
  Cookies.remove(REFRESH_TOKEN)
}

export const setRefreshToken = (token: string) => {
  Cookies.set(REFRESH_TOKEN, token)
}

export const getRefreshToken = (): string | undefined => {
  return Cookies.get(REFRESH_TOKEN)
}
